#bg{
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  background: rgb(235,240,246);
  background: linear-gradient(180deg, rgba(235,240,246,1) 8%, rgba(0,157,174,1) 60%, rgba(30,74,161,1) 100%);
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
}

.header-bg{
  background: #F4F6FF !important; 
}

.main-icon-color{
  color: #33428C;
  width: 37px !important;
  height: 37px !important;
}

.secondary-icon-color{
  color: #4D4D4D;
  opacity: 0.64;
  width: 28px !important;
  height: 28px !important;
}

.header-icon-color{
  color: #333333;
}

.header-button{
  background: #FFF !important;
  border-radius: 10px !important;
}

.main-card{
  border-radius: 20px !important;
}