.typed-cursor{
  display: none !important;
}

.animated-container{
  justify-content: center !important;
}

.pricing-feature-icons{
  color: #314584;
  font-weight: bold !important;
  width: 1.3rem !important;
  height: 1.3rem !important;
}

.package-list-item{
  color: #404040;
  word-break: break-all;
  max-width: 100%;

}